import { fromEvent, Subject } from 'rxjs';
import { isNil } from 'lodash-es';
import { filter } from 'rxjs/operators';

import { isEmpty } from '@bp/shared/utilities/core';

import { IAppStorageSetItemEvent, IAppStorageRemoveItemEvent, IAppStorageServiceConfig } from './models';

type DyingStorageItem<T> = {
	value: T;
	aliveUntil: number;
};

export class AppStorageService {

	private readonly __errors$ = new Subject<string>();

	errors$ = this.__errors$.asObservable();

	private readonly __removeItems$ = new Subject<IAppStorageRemoveItemEvent>();

	removeItems$ = this.__removeItems$.asObservable();

	private readonly __setItems$ = new Subject<IAppStorageSetItemEvent>();

	setItems$ = this.__setItems$.asObservable();

	private readonly __warnings$ = new Subject<string>();

	warnings$ = this.__warnings$.asObservable();

	private readonly __prefix = isEmpty(this.__config.prefix)
		? 'ls'
		: this.__buildPrefix(this.__config.prefix);

	private readonly __storageAreaType = this.__config.storageAreaType;

	private readonly __storageArea: Storage | null = this.__tryGetStorageArea();

	readonly change$ = fromEvent<StorageEvent>(window, 'storage').pipe(
		// Due to share nature of local and session storage, event is triggered for both of them,
		// so we keep only currently used one.
		filter(event => this.__storageArea === event.storageArea),
	);

	constructor(private readonly __config: IAppStorageServiceConfig) { }

	clearAll(regularExpression?: RegExp | string): boolean {
		// Setting both regular expressions independently
		// Empty strings result in catchall RegExp
		const testRegex = regularExpression ? new RegExp(regularExpression, 'u') : new RegExp('', 'u');

		if (!this.__storageArea) {
			this.__emitNotAvailableWarning();

			return false;
		}

		// eslint-disable-next-line guard-for-in
		for (const key in this.__storageArea) {
			const keyWithoutPrefix = key.replace(this.__prefix, '');

			// Only remove items that are for this app and match the regular expression
			if (key.startsWith(this.__prefix) && testRegex.test(keyWithoutPrefix)) {
				try {
					this.remove(keyWithoutPrefix);
				} catch (error: unknown) {
					this.__emitError(error);

					return false;
				}
			}
		}

		return true;
	}

	deriveKey(key: string): string {
		return `${ this.__prefix }${ key }`;
	}

	get <T>(key: string): T | null {
		if (!this.__storageArea) {
			this.__emitNotAvailableWarning();

			return null;
		}

		const item = this.__storageArea.getItem(this.deriveKey(key));

		if (!item || item === 'null')
			return null;

		try {
			return JSON.parse(item);
		} catch {
			return null;
		}
	}

	keys(): string[] {
		if (!this.__storageArea) {
			this.__emitNotAvailableWarning();

			return [];
		}

		const keys: string[] = [];

		for (const key in this.__storageArea) {
			// Only return keys that are for this app
			if (key.startsWith(this.__prefix)) {
				try {
					keys.push(key.slice(this.__prefix.length));
				} catch (error: unknown) {
					this.__emitError(error);

					return [];
				}
			}
		}

		return keys;
	}

	length(): number {
		if (!this.__storageArea) {
			this.__emitNotAvailableWarning();

			return 0;
		}

		let count = 0;

		for (const key in this.__storageArea) {
			// Only count keys that are for this app
			if (key.startsWith(this.__prefix))
				count++;
		}

		return count;
	}

	remove(...keys: string[]): boolean {
		const result = true;

		if (!this.__storageArea) {
			this.__emitNotAvailableWarning();

			return false;
		}

		for (const key of keys) {
			try {
				this.__storageArea.removeItem(this.deriveKey(key));

				if (this.__removeItems$.observed) {
					this.__removeItems$.next({
						key,
						storageAreaType: this.__storageAreaType,
					});
				}
			} catch (error: unknown) {
				this.__emitError(error);

				return false;
			}
		}

		return result;
	}

	set(key: string, value: unknown): boolean {
		// Let's convert `undefined` values to `null` to get the value consistent
		const storageItem = isNil(value) ? 'null' : JSON.stringify(value);

		if (!this.__storageArea) {
			this.__emitNotAvailableWarning();

			return false;
		}

		try {
			this.__storageArea.setItem(this.deriveKey(key), storageItem);

			if (this.__setItems$.observed) {
				this.__setItems$.next({
					key,
					value,
					storageAreaType: this.__storageAreaType,
				});
			}
		} catch (error: unknown) {
			this.__emitError(error);

			return false;
		}

		return true;
	}

	setIfDifferentFromStored(object: object | null, path: string): void {
		const serializedToStoreObject = JSON.stringify(object);
		const serializedStoredObject = this.get(path);

		if (serializedToStoreObject !== serializedStoredObject)
			this.set(path, object);
	}

	setDying<T>(key: string, value: T, timeToLiveInSeconds: number): boolean {
		return this.set(key, <DyingStorageItem<T>>{
			value,
			aliveUntil: Date.now() + 1000 * timeToLiveInSeconds,
		});
	}

	getAlive<T>(key: string): T | undefined {
		const storedDyingItem = this.get<DyingStorageItem<T> | undefined>(key);

		if (!storedDyingItem)
			return undefined;

		if (storedDyingItem.aliveUntil > Date.now())
			return storedDyingItem.value;

		this.remove(key);

		return undefined;
	}

	private __tryGetStorageArea(): Storage | null {
		try {
			if (this.__storageAreaType in window) {
				const storage = window[this.__storageAreaType];

				// When Safari (OS X or iOS) is in private browsing mode, it
				// appears as though localStorage is available, but trying to
				// call .setItem throws an exception.
				//
				// "QUOTA_EXCEEDED_ERR: DOM Exception 22: An attempt was made
				// to add something to storage that exceeded the quota."
				const key = this.deriveKey(`__${ Math.round(Math.random() * 1e7) }`);

				storage.setItem(key, '');

				storage.removeItem(key);

				return storage;
			}
		} catch (error: unknown) {
			this.__errors$.next(error instanceof Error ? error.message : 'Unknown error');
		}

		return null;
	}

	private __buildPrefix(prefix: string): string {
		// If there is a prefix set in the config let's use that with an appended
		// period for readability:
		const period = '.';

		return prefix.endsWith(period) ? prefix : `${ prefix }${ period }`;
	}

	private __emitNotAvailableWarning(): void {
		this.__warnings$.next(
			`${ this.__storageAreaType } is not available in this environment`,
		);
	}

	private __emitError(error: unknown): void {
		this.__errors$.next(error instanceof Error ? error.message : 'Unknown error');
	}

}
