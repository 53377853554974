import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import '@bp/frontend/startup';

import { AppModule } from './app/app.module';

void platformBrowserDynamic()
	.bootstrapModule(AppModule, {
		ngZoneEventCoalescing: true,
		ngZoneRunCoalescing: true,
		ignoreChangesOutsideZone: true,
	});
