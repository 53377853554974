import { Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { RecordsPage } from '@bp/shared/models/common';
import { assertIsMetadataQueryParamsBased, IEntitiesFirebaseApiService } from '@bp/shared/models/metadata';
import { uuid } from '@bp/shared/utilities/core';

import { EnvironmentService } from '@bp/frontend/services/environment';
import { FirebaseService } from '@bp/frontend/features/firebase';
import {
	CrmOrganization, CrmOrganizationDTO, CrmOrganizationsQueryParams, CRM_ORGANIZATIONS_COLLECTION_PATH,
	WriteCrmOrganizationApiRequest, WRITE_CRM_ORGANIZATION_FB_FN
} from '@bp/frontend/domains/crm/organizations/models';
import { FirebaseDocumentWriter } from '@bp/frontend/features/firebase/models';

@Injectable({
	providedIn: 'root',
})
export class CrmOrganizationsApiService implements IEntitiesFirebaseApiService<CrmOrganization, CrmOrganizationsQueryParams> {

	private readonly __environment = inject(EnvironmentService);

	private readonly __firebaseService = inject(FirebaseService);

	readonly collectionPath = `bp/${ this.__environment.name }/${ CRM_ORGANIZATIONS_COLLECTION_PATH }`;

	private __previousWriteRequestId: string | null = null;

	readonly factory = (dto: CrmOrganizationDTO): CrmOrganization => new CrmOrganization(dto);

	listenToQueriedRecordsPageChanges(query?: CrmOrganizationsQueryParams): Observable<RecordsPage<CrmOrganization>> {
		assertIsMetadataQueryParamsBased(query);

		return this.__firebaseService.listenToQueriedRecordsPageChanges(
			this.collectionPath,
			query,
			this.factory,
		);
	}

	listenToRemoteChanges(id: string): Observable<CrmOrganization | null> {
		return this.__firebaseService
			.listenToDocumentChanges(`${ this.collectionPath }/${ id }`, this.factory);
	}

	get(id: string): Observable<CrmOrganization | null> {
		return this.__firebaseService
			.getDocument(`${ this.collectionPath }/${ id }`, this.factory);
	}

	write(organization: CrmOrganization): Observable<void> {
		if (!organization.id)
			throw new Error('Cannot save a user without id');

		const previousWriteRequestId = this.__previousWriteRequestId;
		const writeRequestId = this.__previousWriteRequestId = uuid();

		return this.__firebaseService
			.callPostCloudFunction<WriteCrmOrganizationApiRequest>(
				`${ WRITE_CRM_ORGANIZATION_FB_FN }${ this.__environment.firebaseFunctionSuffix }`,
				new WriteCrmOrganizationApiRequest({
					previousWriteRequestId,
					writeRequestId,
					writer: FirebaseDocumentWriter.frontend,
					environment: this.__environment.environment,
					data: organization,
				}),
			);
	}

	delete(model: CrmOrganization): Observable<void> {
		return this.__firebaseService.delete(`${ this.collectionPath }/${ model.id }`);
	}

	save(_organization: CrmOrganization): Observable<CrmOrganization> {
		throw new Error('Method not implemented.');
	}

}
