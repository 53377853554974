import { filter, first, map, pairwise, startWith, switchMap } from 'rxjs/operators';
import { isScullyRunning, ScullyLibModule } from '@scullyio/ng-lib';
import { firstValueFrom } from 'rxjs';

import { CommonModule, ViewportScroller } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, NgZone } from '@angular/core';
import type { Event } from '@angular/router';
import {
	ActivatedRouteSnapshot,
	ActivationEnd,
	PRIMARY_OUTLET,
	Router,
	RouteReuseStrategy,
	Scroll
} from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NavigationActionTiming, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';

import { ensureType } from '@bp/shared/utilities/core';
import { Platform } from '@bp/shared/typings';

import {
	FILTER_CONTROL_OPTIONS_TOKEN,
	IFormFieldDefaultOptions,
	SharedComponentsCoreModule
} from '@bp/frontend/components/core';
import { SharedFeaturesValidationModule } from '@bp/frontend/features/validation';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { environment } from '@bp/frontend/environments';
import { fromWaitUntilZoneXhrMacrotasksQueueEmpty } from '@bp/frontend/rxjs';
import { SharedFeaturesAnalyticsModule } from '@bp/frontend/features/analytics';
import { FirebaseAppConfig, FIREBASE_APP_CONFIG } from '@bp/frontend/features/firebase';
import { SharedDomainsCrmUsersCurrentCrmUserStateModule } from '@bp/frontend/domains/crm/users/+current-crm-user-state';
import { SharedDomainsCrmLeadsCurrentCrmLeadStateModule } from '@bp/frontend/domains/crm/leads/+current-crm-lead-state';
import {
	LOCAL_SERVER_META_REDUCERS_PROVIDERS,
	REMOTE_SERVER_META_REDUCERS_PROVIDERS,
	sharedReducer
} from '@bp/frontend/state';
import { SharedDomainsCrmOrganizationsCurrentCrmOrganizationStateModule } from '@bp/frontend/domains/crm/organizations/+current-crm-organization-state';
import { PLATFORM } from '@bp/frontend/services/environment';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { FirebaseApiMockModule } from '@bp/frontend/api-mocking-firebase';
import { GlobalApiMockModule } from '@bp/frontend/api-mocking-global';

import { HomeModule } from '@bp/promo/sections/home';
import { SharedServicesModule } from '@bp/promo/shared/services';
import {
	DEFAULT_LOTTIE_ANIMATION_STRATEGY,
	FreezeAtFractionAnimationStrategy,
	PlayWhenPartiallyInViewportAnimationStrategy
} from '@bp/promo/shared/core';

import { AppRoutingModule } from './app-routing.module';
import { AppStartupService } from './app-startup.service';
import { CoreModule, ErrorsModule, RootComponent, PrimaryRouteAnimationObserverService } from './core';
import { ConfigurableRouteReuseStrategy } from './core/routing';

TelemetryService.log('App module execution begun');

type EnhancedScrollEvent = Scroll & {
	navigatedToSameComponent: boolean;
	snapshot: ActivatedRouteSnapshot | undefined;
};

@NgModule({
	bootstrap: [ RootComponent ],
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.isDeployed,
			registrationStrategy: 'registerWithDelay:1000',
		}),
		StoreModule.forRoot(sharedReducer, {
			runtimeChecks: {
				strictActionImmutability: false,
				strictStateImmutability: false,

				/*
				 * StrictStateSerializability: false
				 * We dont use the built-in immutability check because
				 * It freezes the whole moment structure
				 * So we utilize custom immutabilityCheckMetaReducer
				 */
			},
		}),
		StoreRouterConnectingModule.forRoot({
			routerState: RouterState.Minimal,
			navigationActionTiming: NavigationActionTiming.PostActivation,
		}),
		EffectsModule.forRoot([]),
		SharedPipesModule.forRoot(),
		SharedComponentsCoreModule.forRoot(),
		SharedFeaturesValidationModule.forRoot(),
		SharedServicesModule.forRoot(),
		SharedFeaturesAnalyticsModule.forRoot(),
		SharedDomainsCrmOrganizationsCurrentCrmOrganizationStateModule.forRoot(),
		SharedDomainsCrmUsersCurrentCrmUserStateModule.forRoot(),
		SharedDomainsCrmLeadsCurrentCrmLeadStateModule,
		AppRoutingModule,
		HomeModule,
		CoreModule,
		GlobalApiMockModule,
		FirebaseApiMockModule.forRoot(),
		ErrorsModule, // Should be the last module with routes to properly catch all notfound routes
		ScullyLibModule.forRoot({ useTransferState: true, alwaysMonitor: true }),
	],
	providers: [
		environment.isDeployed ? REMOTE_SERVER_META_REDUCERS_PROVIDERS : LOCAL_SERVER_META_REDUCERS_PROVIDERS,
		{
			provide: FILTER_CONTROL_OPTIONS_TOKEN,
			useValue: <IFormFieldDefaultOptions>{
				appearance: 'round-lg',
				hideErrorText: true,
			},
		},
		{
			provide: FIREBASE_APP_CONFIG,
			useValue: <FirebaseAppConfig>{
				appId: '1:977741303368:web:5efc568445f73b34',
			},
		},
		{
			provide: RouteReuseStrategy,
			useClass: ConfigurableRouteReuseStrategy,
		},
		{
			provide: PLATFORM,
			useValue: ensureType<Platform>('promo'),
		},
		{
			provide: DEFAULT_LOTTIE_ANIMATION_STRATEGY,
			useValue: isScullyRunning()
				? new FreezeAtFractionAnimationStrategy(0)
				: new PlayWhenPartiallyInViewportAnimationStrategy(0.9),
		},
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AppModule {
	constructor(
		private readonly __appStartupService: AppStartupService,
		private readonly __zone: NgZone,
		private readonly __router: Router,
		private readonly __viewportScroller: ViewportScroller,
		private readonly __routeAnimationObserver: PrimaryRouteAnimationObserverService,
	) {
		void this.__whenAppIsStableInitStartupLogic();

		this.__scrollOnRouterNavigation();
	}

	private async __whenAppIsStableInitStartupLogic(): Promise<void> {
		await firstValueFrom(this.__zone.onStable);

		this.__appStartupService.init();
	}

	/**
	 * Angular scrollPositionRestoration doesn't respect async loaded data in the project.
	 * Therefore, we need to wait until the page fully loaded before restoring scroll position.
	 * Scroll Restoration solution is kind of https://github.com/angular/angular/issues/24547#issuecomment-941827675
	 */
	private __scrollOnRouterNavigation(): void {

		/**
		 * In case of `auto` strategy, the browser restores scroll position
		 * itself before NavigationStart event on back navigation.
		 * Therefore, the scroll position of current page saves incorrectly,
		 * because it happens on NavigationStart router event
		 * https://github.com/angular/angular/blob/a92a89b0eb127a59d7e071502b5850e57618ec2d/packages/router/src/router_scroller.ts#L54
		 * This causes to incorrect scroll restoration on forward navigation.
		 *
		 * With `manual` strategy browser does nothing, and so ng router can remember position correctly.
		 */
		window.history.scrollRestoration = 'manual';

		const primaryOutletActivationEndEvent$ = this.__router.events.pipe(
			filter(
				(routeEvent: Event): routeEvent is ActivationEnd => routeEvent instanceof ActivationEnd
					&& !!routeEvent.snapshot.component
					&& routeEvent.snapshot.outlet === PRIMARY_OUTLET,
			),
		);

		const scrollEvent$ = this.__router.events.pipe(
			filter((routeEvent: Event): routeEvent is Scroll => routeEvent instanceof Scroll),
		);

		primaryOutletActivationEndEvent$
			.pipe(
				startWith(null),
				pairwise(),
				switchMap(([ previousActivationEnd, activationEnd ]) => scrollEvent$.pipe(
					map(scrollEvent => ({
						...scrollEvent,
						navigatedToSameComponent:
								previousActivationEnd?.snapshot.component === activationEnd!.snapshot.component,
						snapshot: activationEnd?.snapshot,
					})),
					first(),
				)),
				switchMap(primaryOutletScrollEvent => {
					if (this.__shouldScrollToTop(primaryOutletScrollEvent))
						this.__viewportScroller.scrollToPosition([ 0, 0 ]);

					return this.__routeAnimationObserver.isAnimationDone$.pipe(
						switchMap(() => fromWaitUntilZoneXhrMacrotasksQueueEmpty()),
						map(() => primaryOutletScrollEvent),
					);
				}),
			)
			.subscribe(primaryOutletScrollEvent => {
				if (primaryOutletScrollEvent.position) {
					// Backward navigation
					window.scroll({
						left: primaryOutletScrollEvent.position[0],
						top: primaryOutletScrollEvent.position[1],
						behavior: 'smooth',
					});
				} else if (primaryOutletScrollEvent.anchor) {
					document
						.querySelector(`#${ primaryOutletScrollEvent.anchor }`)
						?.scrollIntoView({ behavior: 'smooth' });
				}
			});
	}

	private __shouldScrollToTop(enhancedScrollEvent: EnhancedScrollEvent): boolean {
		if (!enhancedScrollEvent.navigatedToSameComponent)
			return true;

		if (enhancedScrollEvent.anchor)
			return false;

		return !!enhancedScrollEvent.snapshot?.data['doNotReuseComponent'];
	}
}
